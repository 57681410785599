/**
 * Copyright 2023 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Controller, useFormContext } from "react-hook-form";
import { Case, Switch } from "react-if";
import {
  Label,
  theme,
  MultipleSelect,
  SelectButton,
  Text,
  FlexContainer,
  Box,
  Spacer,
} from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { getFirstItem, noop } from "~/tools";
import { StringProvider } from "../../types";
import { DATA_DESCRIPTION } from "../constants";
import {
  useHandleThresholdDataFilterTabs,
  useSelectProvider,
  useSelectAccount,
} from "../hooks";
import { ThresholdDataFilterTabs } from "../hooks/useHandleThresholdDataFilterTabs";
import { isStringProvider } from "../utils";
import { ThresholdFormData, ThresholdFormFields } from "../validators";
import { RadioOptionList } from "./RadioOptionList";

export function ThresholdData() {
  const { tabs, selectedTab, onTabSelect } = useHandleThresholdDataFilterTabs();

  return (
    <FlexContainer
      css={{ width: "100%" }}
      direction="column"
      alignItems="flex-start"
    >
      <Label required name="Threshold Data" css={{ margin: 0 }} />
      <Text size="sm" color={theme.color.text.text03}>
        {DATA_DESCRIPTION}
      </Text>
      <TabSelector tabs={tabs} onTabSelect={onTabSelect} />
      <Spacer height={theme.spacing.spacing03} />
      <ListSwitch selectedTab={selectedTab} />
    </FlexContainer>
  );
}

type TabSelectorProps = {
  tabs: { name: ThresholdDataFilterTabs; label: string; isActive: boolean }[];
  onTabSelect: (tabName: ThresholdDataFilterTabs) => void;
};

function TabSelector({ tabs, onTabSelect }: TabSelectorProps) {
  return (
    <MultipleSelect>
      {tabs.map(({ name, label, isActive }) => (
        <SelectButton
          key={name}
          name={name}
          value={name}
          labelText={label}
          isActive={isActive}
          onClick={() => onTabSelect(name)}
        />
      ))}
    </MultipleSelect>
  );
}

function ListSwitch({ selectedTab }: { selectedTab: ThresholdDataFilterTabs }) {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext<ThresholdFormData>();

  const {
    providersList,
    onSelect: onSelectProvider,
    selectedProvider,
  } = useSelectProvider(
    getFirstItem(getValues(ThresholdFormFields.PROVIDER)) as StringProvider
  );

  const { accountsLabels } = useSelectAccount(
    getFirstItem(getValues(ThresholdFormFields.PROVIDER))
  );

  // TODO: fetch real services when query is ready
  const servicesList = [
    "Amazon Relational Database Service",
    "Amazon ECS",
    "Other",
  ];

  const handleProviderSelect = (value: string) => {
    if (isStringProvider(value)) {
      onSelectProvider(value);
      setValue(ThresholdFormFields.PROVIDER, [value]);
    }
  };

  const handleAccountSelect = (value: string) => {
    setValue(ThresholdFormFields.ACCOUNTS_IDS, [value]);
  };

  return (
    <FormGroup error={errors[ThresholdFormFields.PROVIDER]}>
      <Box boxStyle="lightGrey">
        <Switch>
          <Case condition={selectedTab === "providers"}>
            <Controller
              name={ThresholdFormFields.PROVIDER}
              render={() => (
                <RadioOptionList
                  optionSelected={selectedProvider}
                  name={ThresholdFormFields.PROVIDER}
                  optionsList={providersList}
                  onSelect={handleProviderSelect}
                />
              )}
            />
          </Case>
          <Case condition={selectedTab === "accounts"}>
            <Controller
              name={ThresholdFormFields.ACCOUNTS_IDS}
              render={() => (
                <RadioOptionList
                  searchable
                  name={ThresholdFormFields.ACCOUNTS_IDS}
                  optionsList={accountsLabels}
                  optionSelected={
                    getValues(ThresholdFormFields.ACCOUNTS_IDS)[0]
                  }
                  onSelect={handleAccountSelect}
                />
              )}
            />
          </Case>
          <Case condition={selectedTab === "services"}>
            <RadioOptionList
              searchable
              optionSelected=""
              name="services-options"
              optionsList={servicesList}
              onSelect={noop}
            />
          </Case>
        </Switch>
      </Box>
    </FormGroup>
  );
}
